.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: aliceblue;
  background-color: #21215F;
}

.example-image {
  width: 100%;
  height: auto;
}

.testDiv {
  
margin-left: auto;
margin-right: auto;
padding-left: 20px;
padding-right: 20px;
}

.centerDiv {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;

  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;

  color: black;
  font-weight: bolder;
  background-color: #B6B6B6;

}


.content {
  flex: 1;
  overflow-y: auto;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}



/* DivTable.com */
.divTable{
	display: table;
	width: 100%;
}

.divTableCell, .divTableHead {	
	display: table-cell;
	padding: 3px 10px;
  vertical-align: top; /* or top, bottom depending on your needs */
}
.divTableHeading {
	
	display: table-header-group;
	font-weight: bold;
}
.divTableFoot {
	
	display: table-footer-group;
	font-weight: bold;
}
.divTableBody {
	display: table-row-group;
}

.divTableRow {
  display: flex;
  align-items: center; /* Center vertically */
}

.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Adjust as needed */
  text-align: center;

  width: 100%; /* Adjust as needed */
  max-width: 300px; /* Adjust as needed for the desired width */
  margin: auto; /* Center horizontally */
}

.center-container img {
  margin-top: 10px; /* Adjust as needed */
  max-width: 80px;
  
}

body {
    background-color: #262261;
    color: white;
    font-family: Arial, sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
}

.example-image {
    width: 100%;
    max-width: 500px;
}

h1 {
    margin-top: 10px;
    font-size: 24px;
}

p {
    font-size: 18px;
    margin: 10px 0;
}

ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
    margin: 0 auto;
    max-width: 500px;
}

li {
    font-size: 18px;
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 10px;
    flex-shrink: 0;
    align-self: flex-start;
}

.arrow-image {
    width: 50px;
    margin-top: 20px;
}

@media (max-width: 500px) {
    .container {
        padding: 10px;
    }
}
