.bottom-sheet {
  position: fixed;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, bottom 0.3s ease-in-out;
  transform: translateY(calc(100% - 20px)); /* Adjust this value to show the handle */
  z-index: 1000; /* Ensure it's above the map */
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  bottom: 78px; /* Adjust this value based on your bottom navigation height */
}

.bottom-sheet.open {
  transform: translateY(0);
  bottom: 56px; /* Remove the offset when the BottomSheet is open */
}

.bottom-sheet-handle {
  width: 60px;
  height: 8px;
  background-color: #888;
  border-radius: 4px;
  margin: 10px auto;
  cursor: pointer;
}


.bottom-sheet-content {
  padding: 20px;
  display: flex;
  flex-wrap: wrap; /* Enable wrapping of buttons */
  justify-content: center; /* Center the buttons */
}

.bottom-sheet-content button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
}

.tap-to-open {
  text-align: center;
  color: #aaa;
  font-size: 12px;
  margin-bottom: 10px;
}

.closed .tap-to-open {
  display: block;
}
