

.example-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}


.schedule-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  color: #1a2c59; /* Dark blue text color */
  font-family: Arial, sans-serif;
  margin-bottom: 100px;
}

.schedule-table th,
.schedule-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 14px;
}

.schedule-table th {
  background-color: #1a2c59; /* Dark blue background for headers */
  color: white;
  font-weight: bold;
}

.schedule-table td {
  background-color: white; /* White background for table cells */
}

.schedule-table tr {
  background-color: white;
}

.schedule-table tr td:nth-child(1),
.schedule-table tr td:nth-child(4) {
  font-weight: bold;
  color: #1a2c59; /* Red color for specific columns */
}

.schedule-table tr.service-row td {
  background-color: #20205E; /* Red background for service rows */
  color: white;
  font-weight: bold;
  text-align: center;
}
