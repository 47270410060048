.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
}

.react-pdf__Document {
  width: 100%;
}

.react-pdf__Page {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  width: 100%;
}
