.map-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.layer-buttons {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
}

.layer-buttons button {
  margin: 5px 0;
}

.reset-button {
  position: absolute;
  top: 20px;
  left: 120px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #0056b3;
}

.flag-marker {
  background-size: cover;
  border-radius: 50%;
  border: 2px solid white;
  z-index: 2;
}

.numbered-marker {
  font-size: 20px;
  font-weight: bold;
  color: black;
}


.post-marker {
  position: absolute;
  width: 40px;
  height: 60px;
  top: -30px;
  left: 0px;  
}

.post-marker-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: bottom center;  
}

.post-marker-number-container {
  position: absolute;
  width: 40px;  
  height: 60px;  
  top: -0px;
  transform-origin: bottom center;  
  z-index: 1;

}

.post-marker-number {
  height: auto;
  margin: 0 auto;
  position: relative;
  width: 25px;
  height: 25px;
  text-align: center;
  top: 6px;  
  color: white;
  font-weight: bold;
  font-size: 20px;
  
}

.label-marker {
  position: absolute;
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  font-weight: bold;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
  opacity: 75%;
}

.label-marker::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #007bff;
}
